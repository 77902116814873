import { AnswerType, Question, SurveyCode, YesNo } from 'lib/types/Survey';

export const SURVEYS: SurveyCode[] = [
  SurveyCode.SMOKING,
  SurveyCode.ALCOHOL_INTAKE,
  SurveyCode.PHYSICAL_ACTIVITY,
  SurveyCode.PATHOLOGICAL_BACKGROUND,
  SurveyCode.BLOOD_PRESSURE,
];

export const QUESTIONS: { [key in SurveyCode]: Question[] } = {
  [SurveyCode.SMOKING]: [
    {
      surveyCode: SurveyCode.SMOKING,
      questionCode: 1,
      answerType: AnswerType.YES_OR_NO,
      text: '¿Fuma?',
      nextQuestionCode: null,
    },
  ],
  [SurveyCode.ALCOHOL_INTAKE]: [
    {
      surveyCode: SurveyCode.ALCOHOL_INTAKE,
      questionCode: 1,
      answerType: AnswerType.AMOUNT,
      text: 'Consumo de Cerveza (300 ml, botella):',
      nextQuestionCode: 2,
    },
    {
      surveyCode: SurveyCode.ALCOHOL_INTAKE,
      questionCode: 2,
      answerType: AnswerType.AMOUNT,
      text: 'Consumo de Vino (150 ml, copa):',
      nextQuestionCode: 3,
    },
    {
      surveyCode: SurveyCode.ALCOHOL_INTAKE,
      questionCode: 3,
      answerType: AnswerType.AMOUNT,
      text: 'Consumo de Cócteles (150 ml, copa):',
      nextQuestionCode: 4,
    },
    {
      surveyCode: SurveyCode.ALCOHOL_INTAKE,
      questionCode: 4,
      answerType: AnswerType.AMOUNT,
      text: 'Consumo de Alcohol Destilado (50 ml - ron, whisky, vodka, etc.):',
      nextQuestionCode: null,
    },
  ],
  [SurveyCode.PHYSICAL_ACTIVITY]: [
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 1,
      answerType: AnswerType.YES_OR_NO,
      text: '¿Exige su trabajo una actividad física intensa que implica una aceleración importante de la respiración o del ritmo cardíaco, como [levantar pesas, cavar o trabajos de construcción] durante al menos 10 minutos consecutivos?',
      next: {
        [YesNo.YES]: 2,
        [YesNo.NO]: 4,
      },
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 2,
      answerType: AnswerType.DAYS,
      text: 'En una semana típica, ¿cuántos días realiza usted actividades físicas intensas en su trabajo?',
      nextQuestionCode: 3,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 3,
      answerType: AnswerType.HOURS_MINUTES,
      text: 'En uno de esos días en los que realiza actividades físicas intensas, ¿cuánto tiempo suele dedicar a esas actividades?',
      nextQuestionCode: 4,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 4,
      answerType: AnswerType.YES_OR_NO,
      text: '¿Exige su trabajo una actividad de intensidad moderada que implica una ligera aceleración de la respiración o del ritmo cardíaco, como caminar deprisa [o transportar pesos ligeros] durante al menos 10 minutos consecutivos?',
      next: {
        [YesNo.YES]: 5,
        [YesNo.NO]: 7,
      },
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 5,
      answerType: AnswerType.DAYS,
      text: 'En una semana típica, ¿cuántos días realiza usted actividades de intensidad moderada en su trabajo?',
      nextQuestionCode: 6,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 6,
      answerType: AnswerType.HOURS_MINUTES,
      text: 'En uno de esos días en los que realiza actividades físicas de intensidad moderada, ¿cuánto tiempo suele dedicar a esas actividades?',
      nextQuestionCode: 7,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 7,
      answerType: AnswerType.YES_OR_NO,
      text: '¿Camina usted o usa usted una bicicleta al menos 10 minutos consecutivos en sus desplazamientos?',
      next: {
        [YesNo.YES]: 8,
        [YesNo.NO]: 10,
      },
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 8,
      answerType: AnswerType.DAYS,
      text: 'En una semana típica, ¿cuántos días camina o va en bicicleta al menos 10 minutos consecutivos en sus desplazamientos?',
      nextQuestionCode: 9,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 9,
      answerType: AnswerType.HOURS_MINUTES,
      text: 'En un día típico, ¿cuánto tiempo pasa caminando o yendo en bicicleta para desplazarse?',
      nextQuestionCode: 10,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 10,
      answerType: AnswerType.YES_OR_NO,
      text: '¿En su tiempo libre, practica usted deportes/fitness intensos que implican una aceleración importante de la respiración o del ritmo cardíaco como [correr, jugar al fútbol] durante al menos 10 minutos consecutivos?',
      next: {
        [YesNo.YES]: 11,
        [YesNo.NO]: 13,
      },
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 11,
      answerType: AnswerType.DAYS,
      text: 'En una semana típica, ¿cuántos días practica usted deportes/fitness intensos en su tiempo libre?',
      nextQuestionCode: 12,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 12,
      answerType: AnswerType.HOURS_MINUTES,
      text: 'En uno de esos días en los que practica deportes/fitness intensos, ¿cuánto tiempo suele dedicar a esas actividades?',
      nextQuestionCode: 13,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 13,
      answerType: AnswerType.YES_OR_NO,
      text: '¿En su tiempo libre practica usted alguna actividad de intensidad moderada que implica una ligera aceleración de la respiración o del ritmo cardíaco, como caminar deprisa, [ir en bicicleta, nadar, jugar al volleyball] durante al menos 10 minutos consecutivos?',
      next: {
        [YesNo.YES]: 14,
        [YesNo.NO]: 16,
      },
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 14,
      answerType: AnswerType.DAYS,
      text: 'En una semana típica, ¿cuántos días practica usted actividades físicas de intensidad moderada en su tiempo libre?',
      nextQuestionCode: 15,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 15,
      answerType: AnswerType.HOURS_MINUTES,
      text: 'En uno de esos días en los que practica actividades físicas de intensidad moderada, ¿cuánto tiempo suele dedicar a esas actividades?',
      nextQuestionCode: 16,
    },
    {
      surveyCode: SurveyCode.PHYSICAL_ACTIVITY,
      questionCode: 16,
      answerType: AnswerType.HOURS_MINUTES,
      text: '¿Cuánto tiempo suele pasar sentado o recostado en un día típico?',
      nextQuestionCode: null,
    },
  ],
  [SurveyCode.PATHOLOGICAL_BACKGROUND]: [
    {
      surveyCode: SurveyCode.PATHOLOGICAL_BACKGROUND,
      questionCode: 1,
      answerType: AnswerType.YES_OR_NO,
      text: '¿Padece o ha padecido enfermedades cardiovasculares como cardiopatía coronaria, arritmias, insuficiencia cardíaca, etc.?',
      nextQuestionCode: 2,
    },
    {
      surveyCode: SurveyCode.PATHOLOGICAL_BACKGROUND,
      questionCode: 2,
      answerType: AnswerType.YES_OR_NO,
      text: '¿Padece o ha padecido diabetes?',
      nextQuestionCode: null,
    },
  ],
  [SurveyCode.BLOOD_PRESSURE]: [
    {
      surveyCode: SurveyCode.BLOOD_PRESSURE,
      questionCode: 1,
      answerType: AnswerType.YES_OR_NO,
      text: '¿Toma medicamentos para bajar la presión arterial?',
      nextQuestionCode: 2,
    },
    {
      surveyCode: SurveyCode.BLOOD_PRESSURE,
      questionCode: 2,
      answerType: AnswerType.BLOOD_PRESSURE,
      text: 'Ingrese las mediciones de presión arterial:',
      nextQuestionCode: null,
    },
  ],
};

export default QUESTIONS;
