import axios, { AxiosRequestConfig } from 'axios';
import jwtDecode from 'jwt-decode';
import { setAccessToken } from 'store/features/app';
import { Store } from 'store/store';

let store: Store;

export const injectStore = (_store: Store) => {
  store = _store;
};

const client = axios.create({
  baseURL: process.env.REACT_APP_SERVER,
});

client.interceptors.request.use(async (config: AxiosRequestConfig) => {
  let accessToken = store.getState().app.accessToken;
  config.withCredentials = true;

  // it's login or logout request; no access token required
  if (
    config.url === '/login' ||
    config.url === '/logout' ||
    config.url === '/refresh_token'
  ) {
    return config;
  }

  // access to other urls requires a valid access token
  if (!accessToken || (accessToken && !isValidAccessToken(accessToken))) {
    const accessToken = await fetchAccessToken();
    store.dispatch(setAccessToken(accessToken));
  }

  // most likely the cookie has expired, redirect to login page
  if (!accessToken) {
    store.dispatch(setAccessToken(null));
    return;
  }

  if (config?.headers) {
    config.headers.authorization = `Bearer ${accessToken}`;
    return config;
  }
});

client.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

export default client;

export const fetchAccessToken = async () => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_SERVER}${process.env.REACT_APP_REFRESH_TOKEN_PATH}`,
      {
        method: 'POST',
        credentials: 'include',
      },
    );
    const { ok, accessToken } = await response.json();
    if (ok) {
      return accessToken;
    }

    return null;
  } catch (error) {
    console.error(error);
    return null;
  }
};

export const isValidAccessToken = (token: string) => {
  if (!token) {
    return false;
  }

  try {
    const decodedToken: { exp: number } = jwtDecode(token);
    const exp = decodedToken?.exp;
    // check if the token has expired
    return Date.now() <= exp * 1000;
  } catch {
    return false;
  }
};
