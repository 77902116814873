export enum NavOption {
  ORGANIZATION_CREATE = 'ORGANIZATION_CREATE',
  EMPLOYEE_BULK_UPLOAD = 'EMPLOYEE_BULK_UPLOAD',
  STUDY_CREATE = 'STUDY_CREATE',
  PATIENT_CREATE = 'PATIENT_CREATE',
  STUDY_DISABLE = 'STUDY_DISABLE',
  SURVEY_START = 'SURVEY_START',
  UPLOAD_METRICS_FILE = 'UPLOAD_METRICS_FILE',
  REPORT_CREATE = 'REPORT_CREATE',
  STUDY_STATUS = 'STUDY_STATUS',
}

export const NavOptionMap: { [key in NavOption]: string } = {
  [NavOption.ORGANIZATION_CREATE]: 'Crear Empresa',
  [NavOption.EMPLOYEE_BULK_UPLOAD]: 'Subir Empleados',
  [NavOption.STUDY_CREATE]: 'Crear Estudio',
  [NavOption.PATIENT_CREATE]: 'Crear Paciente',
  [NavOption.STUDY_DISABLE]: 'Deshabilitar Estudio',
  [NavOption.SURVEY_START]: 'Llenar Encuesta',
  [NavOption.UPLOAD_METRICS_FILE]: 'Subir Archivo de Métricas',
  [NavOption.REPORT_CREATE]: 'Crear Reporte',
  [NavOption.STUDY_STATUS]: 'Estado del Estudio',
};
