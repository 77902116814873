import { PayloadAction, createSlice, current } from '@reduxjs/toolkit';
import { Patient } from 'src/lib/types/Patient';
import { Study } from 'src/lib/types/Study';
import { QUESTIONS, SURVEYS } from 'components/surveys/content/SurveyData';
import {
  AnswerType,
  Question,
  Risk,
  SurveyAnswer,
  SurveyCode,
} from 'lib/types/Survey';
import type { RootState } from 'store/store';

// Define a type for the slice state
interface EntitiesState {
  patient: Patient | null;
  study: Study | null;
  currentSurvey: SurveyCode;
  currentSurveyQuestion: Question | null;
  suveyIsOver: boolean;
  surveyQuestions: Question[];
  surveyAnswers: SurveyAnswer[];
  surveyRisks: Risk[];
}

// Define the initial state using that type
const initialState: EntitiesState = {
  patient: null,
  study: null,
  currentSurvey: SURVEYS[0],
  currentSurveyQuestion: QUESTIONS[SURVEYS[0]][0],
  suveyIsOver: true,
  surveyQuestions: [QUESTIONS[SURVEYS[0]][0]],
  surveyAnswers: [],
  surveyRisks: [],
};

export const entitiesSlice = createSlice({
  name: 'entities',
  initialState,
  reducers: {
    setPatient: (state, action: PayloadAction<Patient>) => {
      state.patient = action.payload;
    },
    setStudy: (state, action: PayloadAction<Study | null>) => {
      state.study = action.payload;
    },
    previousQuestion: (state) => {
      const currentSurveyQuestion = state.currentSurveyQuestion;

      if (!currentSurveyQuestion) {
        return;
      }

      const surveyQuestions = state.surveyQuestions;
      const currentSurveyQuestionIndex = surveyQuestions.findIndex(
        (item) =>
          item.surveyCode === currentSurveyQuestion.surveyCode &&
          item.questionCode === currentSurveyQuestion.questionCode,
      );

      if (currentSurveyQuestionIndex === 0) {
        return;
      }
      const previousQuestion = surveyQuestions[currentSurveyQuestionIndex - 1];

      state.currentSurvey = previousQuestion.surveyCode;
      state.currentSurveyQuestion = previousQuestion;
    },
    nextQuestion: (state) => {
      const addQuestion = (question: Question) => {
        // check if question was already added
        const isQuestionAlreadyAdded = state.surveyQuestions.find(
          (item) =>
            item.surveyCode === question.surveyCode &&
            item.questionCode === question.questionCode,
        );

        // if not, added at the end of the array
        if (!isQuestionAlreadyAdded) {
          const existingSurveyQuestions = [...state.surveyQuestions];
          existingSurveyQuestions.push(question);
          state.surveyQuestions = existingSurveyQuestions;
        }
      };

      const currentSurvey = state.currentSurvey;
      const currentSurveyQuestion = state.currentSurveyQuestion;

      if (!currentSurveyQuestion) {
        return;
      }

      let nextQuestion = null;

      // current question has 'nextQuestionCode'
      if (currentSurveyQuestion.nextQuestionCode) {
        nextQuestion =
          QUESTIONS[currentSurvey].find(
            (item) =>
              item.questionCode === currentSurveyQuestion.nextQuestionCode,
          ) || null;
        state.currentSurveyQuestion = nextQuestion;
        if (nextQuestion) {
          addQuestion(nextQuestion);
        }
        return;
      }

      // current question has 'next'
      if (currentSurveyQuestion.next) {
        const answer = state.surveyAnswers.find(
          (item) =>
            item.surveyCode === currentSurvey &&
            item.questionCode === currentSurveyQuestion.questionCode,
        );

        if (answer?.answerType === AnswerType.YES_OR_NO) {
          nextQuestion =
            QUESTIONS[currentSurvey].find(
              (item) =>
                item.questionCode === currentSurveyQuestion.next[answer.answer],
            ) || null;

          state.currentSurveyQuestion = nextQuestion;
          if (nextQuestion) {
            addQuestion(nextQuestion);
          }
        }
        return;
      }

      // no next question in current survey; check if there is another survey
      const currentSurveyIndex = SURVEYS.findIndex(
        (item) => item === currentSurvey,
      );

      // END OF SURVEYS
      if (currentSurveyIndex === SURVEYS.length - 1) {
        state.suveyIsOver = true;
        return;
      }

      // get the first question of next survey
      const nextSurvey = SURVEYS[currentSurveyIndex + 1];
      state.currentSurvey = nextSurvey;
      state.currentSurveyQuestion = QUESTIONS[nextSurvey][0];
      addQuestion(QUESTIONS[nextSurvey][0]);
    },
    resetSurvey: (state) => {
      state.patient = null;
      state.study = null;
      state.currentSurvey = SURVEYS[0];
      state.currentSurveyQuestion = QUESTIONS[SURVEYS[0]][0];
      state.suveyIsOver = false;
      state.surveyQuestions = [QUESTIONS[SURVEYS[0]][0]];
      state.surveyAnswers = [];
    },
    startSurvey: (state) => {
      state.suveyIsOver = false;
    },
    addSurveyAnswer: (state, action: PayloadAction<SurveyAnswer>) => {
      let existingAnswers: SurveyAnswer[] = [];
      const newAnswer = action.payload;

      if (state.surveyAnswers && state.surveyAnswers.length > 0) {
        existingAnswers = [...state.surveyAnswers].filter((item) => {
          // if answer has "next" attribute,
          // remove all the following answers for current survey from surveyAnswers.
          // ELSE remove only the existing answer of this same code and survey, if any
          return state.currentSurveyQuestion?.next
            ? !(
                item.surveyCode === newAnswer.surveyCode &&
                item.questionCode >= newAnswer.questionCode
              )
            : !(
                item.surveyCode === newAnswer.surveyCode &&
                item.questionCode === newAnswer.questionCode
              );
        });
      }
      // add the new answer
      existingAnswers.push(newAnswer);

      // if answer has "next" attribute,
      // remove all the questions for current survey that would come after this one.
      if (state.currentSurveyQuestion?.next) {
        const surveyQuestions = [...state.surveyQuestions].filter(
          (item) =>
            !(
              item.surveyCode === newAnswer.surveyCode &&
              item.questionCode > newAnswer.questionCode
            ),
        );
        state.surveyQuestions = surveyQuestions;
      }

      state.surveyAnswers = existingAnswers;
    },
    addRisk: (state, action: PayloadAction<Risk>) => {
      const risk = action.payload;
      // if a calculated risk is already present for this same surveyCode, remove it first
      const existingRisks = [...state.surveyRisks].filter(
        (item) => item.surveyCode !== risk.surveyCode,
      );
      // add the calculated risk
      existingRisks.push(risk);
      state.surveyRisks = existingRisks;
    },
  },
});

export const {
  setPatient,
  setStudy,
  previousQuestion,
  nextQuestion,
  addSurveyAnswer,
  resetSurvey,
  addRisk,
  startSurvey,
} = entitiesSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectPatient = (state: RootState) => state.entities.patient;
export const selectStudy = (state: RootState) => state.entities.study;
export const selectCurrentSurvey = (state: RootState) =>
  state.entities.currentSurvey;
export const selectCurrentSurveyQuestion = (state: RootState) =>
  state.entities.currentSurveyQuestion;
export const selectIsSurveyOver = (state: RootState) =>
  state.entities.suveyIsOver;
export const selectSurveyAnswers = (state: RootState) =>
  state.entities.surveyAnswers;
export const selectSurveyAnswer = (
  state: RootState,
  surveyCode?: SurveyCode,
  questionCode?: number,
) => {
  if (!surveyCode || !questionCode) {
    return null;
  }
  return state.entities.surveyAnswers.find(
    (item) =>
      item.surveyCode === surveyCode && item.questionCode === questionCode,
  );
};
export const selectEnableNext = (state: RootState) => {
  const currentSurveyQuestion = state.entities.currentSurveyQuestion;
  const surveyAnswers = state.entities.surveyAnswers;
  if (currentSurveyQuestion && surveyAnswers.length > 0) {
    const isCurrentQuestionAnswered = surveyAnswers.find(
      (item) =>
        item.surveyCode === currentSurveyQuestion.surveyCode &&
        item.questionCode === currentSurveyQuestion.questionCode,
    );
    return isCurrentQuestionAnswered;
  }
  return false;
};
export const selectRisks = (state: RootState) => {
  return state.entities.surveyRisks;
};

export default entitiesSlice.reducer;
