import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store/store';

// Define a type for the slice state
interface UIState {
  accessToken: string | null;
  error: string;
  successMessage: string;
}

// Define the initial state using that type
const initialState: UIState = {
  accessToken: '',
  error: '',
  successMessage: '',
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setAccessToken: (state, action: PayloadAction<string | null>) => {
      state.accessToken = action.payload;
    },
    setError: (state, action: PayloadAction<string>) => {
      state.error = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<string>) => {
      state.successMessage = action.payload;
    },
  },
});

export const { setAccessToken, setError, setSuccessMessage } = appSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectAccessToken = (state: RootState) => state.app.accessToken;
export const selectError = (state: RootState) => state.app.error;
export const selectSuccessMessage = (state: RootState) =>
  state.app.successMessage;

export default appSlice.reducer;
