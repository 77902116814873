import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { NavOption } from 'lib/types/Navigation';
import type { RootState } from 'store/store';

// Define a type for the slice state
interface UIState {
  activeNavOption: NavOption;
}

// Define the initial state using that type
const initialState: UIState = {
  activeNavOption: NavOption.SURVEY_START,
};

export const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setActiveNavOption: (state, action: PayloadAction<NavOption>) => {
      state.activeNavOption = action.payload;
    },
  },
});

export const { setActiveNavOption } = uiSlice.actions;

// Other code such as selectors can use the imported `RootState` type
export const selectActiveNavOption = (state: RootState) =>
  state.ui.activeNavOption;

export default uiSlice.reducer;
