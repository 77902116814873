import { configureStore } from '@reduxjs/toolkit';
import appReducers from 'store/features/app';
import entitiesReducers from 'store/features/entities';
import uiReducers from 'store/features/ui';

export const store = configureStore({
  reducer: {
    entities: entitiesReducers,
    ui: uiReducers,
    app: appReducers,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export type Store = typeof store;
