import { LabelColors } from 'src/components/base/Label';

export enum AnswerType {
  YES_OR_NO = 'yn',
  AMOUNT = 'a',
  DAYS = 'd',
  HOURS_MINUTES = 'hm',
  BLOOD_PRESSURE = 'bp',
}

export enum YesNo {
  YES = 'y',
  NO = 'n',
}

export const YesNoMap: { [key in YesNo]: string } = {
  [YesNo.YES]: 'Sí',
  [YesNo.NO]: 'No',
};

export type Answer =
  | YesNo
  | number
  | { hours: number; minutes: number }
  | { systolic: number; diastolic: number };

export enum SurveyCode {
  SMOKING = 'SMOKING',
  ALCOHOL_INTAKE = 'ALCOHOL_INTAKE',
  PHYSICAL_ACTIVITY = 'PHYSICAL_ACTIVITY',
  PATHOLOGICAL_BACKGROUND = 'PATHOLOGICAL_BACKGROUND',
  BLOOD_PRESSURE = 'BLOOD_PRESSURE',
}

export const SuveyCodeTextMap = {
  [SurveyCode.SMOKING]: 'Tabaquismo',
  [SurveyCode.ALCOHOL_INTAKE]: 'Consumo de Alcohol',
  [SurveyCode.PHYSICAL_ACTIVITY]: 'Actividad Física',
  [SurveyCode.PATHOLOGICAL_BACKGROUND]: 'Antecedentes Patológicos',
  [SurveyCode.BLOOD_PRESSURE]: 'Presión Arterial',
};

export type NextQuestion = { [key in YesNo]: number };

export type Question =
  | {
      surveyCode: SurveyCode;
      questionCode: number;
      answerType:
        | AnswerType.YES_OR_NO
        | AnswerType.DAYS
        | AnswerType.HOURS_MINUTES
        | AnswerType.AMOUNT
        | AnswerType.BLOOD_PRESSURE;
      text: string;
      nextQuestionCode: number | null;
      next?: never;
    }
  | {
      surveyCode: SurveyCode;
      questionCode: number;
      answerType:
        | AnswerType.YES_OR_NO
        | AnswerType.DAYS
        | AnswerType.HOURS_MINUTES
        | AnswerType.AMOUNT
        | AnswerType.BLOOD_PRESSURE;
      text: string;
      nextQuestionCode?: never;
      next: NextQuestion;
    };

export enum PhysicalActivity {
  HIGH = 'h',
  MODERATE = 'm',
  LOW = 'l',
}

export const PhysicalActivityLabelMap: { [key in PhysicalActivity]: string } = {
  [PhysicalActivity.HIGH]: 'Alto',
  [PhysicalActivity.MODERATE]: 'Moderado',
  [PhysicalActivity.LOW]: 'Bajo',
};

export const PhysicalActivityColorMap: {
  [key in PhysicalActivity]: LabelColors;
} = {
  [PhysicalActivity.HIGH]: 'green',
  [PhysicalActivity.MODERATE]: 'yellow',
  [PhysicalActivity.LOW]: 'red',
};

export type SurveyAnswer =
  | {
      surveyCode: SurveyCode;
      questionCode: number;
      answerType: AnswerType.YES_OR_NO;
      answer: YesNo;
    }
  | {
      surveyCode: SurveyCode;
      questionCode: number;
      answerType: AnswerType.AMOUNT;
      answer: number;
    }
  | {
      surveyCode: SurveyCode;
      questionCode: number;
      answerType: AnswerType.DAYS;
      answer: number;
    }
  | {
      surveyCode: SurveyCode;
      questionCode: number;
      answerType: AnswerType.HOURS_MINUTES;
      answer: { hours: number; minutes: number };
    }
  | {
      surveyCode: SurveyCode;
      questionCode: number;
      answerType: AnswerType.BLOOD_PRESSURE;
      answer: { systolic: number; diastolic: number };
    };

// Risk Calculation

export enum RiskLevel {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}

export enum PhysicalActivityRiskLevel {
  LOW = 'LOW',
  MODERATE = 'MODERATE',
  HIGH = 'HIGH',
}

export enum ImcRiskLevel {
  LOW = 'LOW',
  NORMAL = 'NORMAL',
  OVERWEIGHT = 'OVERWEIGHT',
  OBESITY_GRADE_1 = 'OBESITY_GRADE_1',
  OBESITY_GRADE_2 = 'OBESITY_GRADE_2',
  OBESITY_GRADE_3 = 'OBESITY_GRADE_3',
}

export enum FatPercentageRiskLevel {
  LOW = 'LOW',
  HEALTHY = 'HEALTHY',
  HIGH = 'HIGH',
  OBESITY = 'OBESITY',
}

export enum WaistPerimeterRiskLevel {
  NORMAL = 'NORMAL',
  HIGH = 'HIGH',
}

export type Risk = {
  surveyCode: SurveyCode;
  level: RiskLevel | PhysicalActivityRiskLevel;
  alcoholIntake?: number;
  MET?: number;
};

// ----------- RISK LEVEL ---------- //

export const RiskLevelTextMap: { [key in RiskLevel]: string } = {
  [RiskLevel.LOW]: 'Bajo',
  [RiskLevel.MODERATE]: 'Moderado',
  [RiskLevel.HIGH]: 'Alto',
};

export const RiskLevelColorMap: { [key in RiskLevel]: LabelColors } = {
  [RiskLevel.LOW]: 'green',
  [RiskLevel.MODERATE]: 'yellow',
  [RiskLevel.HIGH]: 'red',
};

/* Report charts colors */
export const RiskLevelTextColorMap: { [key: string]: string } = {
  [RiskLevelTextMap[RiskLevel.LOW]]: 'green',
  [RiskLevelTextMap[RiskLevel.MODERATE]]: 'yellow',
  [RiskLevelTextMap[RiskLevel.HIGH]]: 'red',
};

// ----------- PHYSICAL ACTIVITY RISK LEVEL ---------- //

export const PhysicalActivityRiskLevelTextMap: { [key in RiskLevel]: string } =
  {
    [RiskLevel.LOW]: 'Bajo',
    [RiskLevel.MODERATE]: 'Moderado',
    [RiskLevel.HIGH]: 'Alto',
  };

export const PhysicalActivityRiskLevelColorMap: {
  [key in RiskLevel]: LabelColors;
} = {
  [RiskLevel.LOW]: 'red',
  [RiskLevel.MODERATE]: 'yellow',
  [RiskLevel.HIGH]: 'green',
};

/* Report charts colors */
export const PhysicalActivityRiskLevelTextColorMap: {
  [key: string]: LabelColors;
} = {
  [PhysicalActivityRiskLevelTextMap[RiskLevel.LOW]]: 'red',
  [PhysicalActivityRiskLevelTextMap[RiskLevel.MODERATE]]: 'yellow',
  [PhysicalActivityRiskLevelTextMap[RiskLevel.HIGH]]: 'green',
};

// ----------- IMC RISK LEVEL ---------- //

export const ImcRiskLevelTextMap: { [key in ImcRiskLevel]: string } = {
  [ImcRiskLevel.LOW]: 'Bajo',
  [ImcRiskLevel.NORMAL]: 'Normal',
  [ImcRiskLevel.OVERWEIGHT]: 'Sobrepeso',
  [ImcRiskLevel.OBESITY_GRADE_1]: 'Obesidad Grado 1',
  [ImcRiskLevel.OBESITY_GRADE_2]: 'Obesidad Grado 2',
  [ImcRiskLevel.OBESITY_GRADE_3]: 'Obesidad Grado 3',
};

export const ImcRiskLevelColorMap: {
  [key in ImcRiskLevel]: LabelColors;
} = {
  [ImcRiskLevel.LOW]: 'blue',
  [ImcRiskLevel.NORMAL]: 'green',
  [ImcRiskLevel.OVERWEIGHT]: 'yellow',
  [ImcRiskLevel.OBESITY_GRADE_1]: 'lightorange',
  [ImcRiskLevel.OBESITY_GRADE_2]: 'deeporange',
  [ImcRiskLevel.OBESITY_GRADE_3]: 'red',
};

/* Report charts colors */
export const ImcRiskLevelTextColorMap: {
  [key: string]: LabelColors;
} = {
  [ImcRiskLevelTextMap[ImcRiskLevel.LOW]]: 'blue',
  [ImcRiskLevelTextMap[ImcRiskLevel.NORMAL]]: 'green',
  [ImcRiskLevelTextMap[ImcRiskLevel.OVERWEIGHT]]: 'yellow',
  [ImcRiskLevelTextMap[ImcRiskLevel.OBESITY_GRADE_1]]: 'lightorange',
  [ImcRiskLevelTextMap[ImcRiskLevel.OBESITY_GRADE_2]]: 'deeporange',
  [ImcRiskLevelTextMap[ImcRiskLevel.OBESITY_GRADE_3]]: 'red',
};

// ----------- FAT RISK LEVEL ---------- //

export const FatPercentageRiskLevelTextMap: {
  [key in FatPercentageRiskLevel]: string;
} = {
  [FatPercentageRiskLevel.LOW]: 'Bajo',
  [FatPercentageRiskLevel.HEALTHY]: 'Saludable',
  [FatPercentageRiskLevel.HIGH]: 'Alto',
  [FatPercentageRiskLevel.OBESITY]: 'Obesidad',
};

export const FatPercentageRiskLevelColorMap: {
  [key in FatPercentageRiskLevel]: LabelColors;
} = {
  [FatPercentageRiskLevel.LOW]: 'blue',
  [FatPercentageRiskLevel.HEALTHY]: 'green',
  [FatPercentageRiskLevel.HIGH]: 'yellow',
  [FatPercentageRiskLevel.OBESITY]: 'red',
};

/* Report charts colors */
export const FatPercentageRiskLevelTextColorMap: {
  [key: string]: LabelColors;
} = {
  [FatPercentageRiskLevelTextMap[FatPercentageRiskLevel.LOW]]: 'blue',
  [FatPercentageRiskLevelTextMap[FatPercentageRiskLevel.HEALTHY]]: 'green',
  [FatPercentageRiskLevelTextMap[FatPercentageRiskLevel.HIGH]]: 'yellow',
  [FatPercentageRiskLevelTextMap[FatPercentageRiskLevel.OBESITY]]: 'red',
};

// ----------- WAIST PERIMETER LEVEL ---------- //

export const WaistPerimeterRiskLevelTextMap: {
  [key in WaistPerimeterRiskLevel]: string;
} = {
  [WaistPerimeterRiskLevel.NORMAL]: 'Normal',
  [WaistPerimeterRiskLevel.HIGH]: 'Alto',
};

export const WaistPerimeterRiskLevelColorMap: {
  [key in WaistPerimeterRiskLevel]: LabelColors;
} = {
  [WaistPerimeterRiskLevel.NORMAL]: 'green',
  [WaistPerimeterRiskLevel.HIGH]: 'red',
};

/* Report charts colors */
export const WaistPerimeterRiskLevelTextColorMap: { [key: string]: string } = {
  [WaistPerimeterRiskLevelTextMap[WaistPerimeterRiskLevel.NORMAL]]: 'green',
  [WaistPerimeterRiskLevelTextMap[WaistPerimeterRiskLevel.HIGH]]: 'red',
};

// ----------- SURVEY ENTRIES ---------- //

export type SurveyEntry = {
  identificationId?: string;
  surveyClass: SurveyCode | 'METRICS';
  attribute: number | string;
  type: string;
  value: number | string;
};
